
import { defineComponent, ref, onMounted, computed, onUpdated } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import AddCompanyModal from "@/components/modals/forms/AddCompanyModal.vue";
import AddAccountModal from "@/components/modals/forms/AddAccountModal.vue";

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import customers from "@/core/data/customers";
import roc_list, { business_types } from "@/core/data/genericData";
import { ICustomer } from "@/core/data/customers";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import axios from "axios";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import { useRouter } from "vue-router";
import moment from "moment";

export default defineComponent({
  name: "customers-listing",
  components: {
    ExportCustomerModal,
    AddAccountModal,
  },

  setup() {
    const store = useStore();
    const router = useRouter();
    const count = ref(0);
    const checkedCompany = ref([]);
    const loadingData = ref<boolean>(false);
    var ids = ref(0);

    interface WICompanies {
      account_name;
      amount;
    }

    var paginationData = ref({});

    paginationData.value = {
      start: 0,
      end: 0,
      total: 0,
      perPage: 5,
      activePage: 1,
      totPage: 1,
      mmaxPageg: 6,
    };

    const refreshData = () => {
      getReports(fromDate, toDate);
    };

    const chkData = () => {
      console.log(fromDate.value);
    };

    const changePageChange = (page) => {
      console.log(page);
      paginationData.value["activePage"] = page;
      getReports(fromDate, toDate);
    };

    var tableData = ref<Array<WICompanies>>([]);
    var tableDataExpendeture = ref<Array<WICompanies>>([]);

    const getReports = async (fromDate, toDate) => {
      loadingData.value = true;
      try {
        var startDate = moment(fromDate).format("YYYY-MM-DD");
        var endDate = moment(toDate).format("YYYY-MM-DD");
        console.log(startDate);
        var values = {
          from_date: startDate,
          to_date: endDate,
        };
        console.log(values);
        await store
          .dispatch(ActionsFi.CUST_GET_ASSETS_LIABILITIES_REPORT, values)
          .then(({ data }) => {
            debugger;
            console.log(JSON.stringify("trail data" + JSON.stringify(data)));
            tableData.value = [];
            // debitTotal.value = data?.current_total?.debit_total
            //   ? data?.current_total?.debit_total
            //   : "";
            // creditTotal.value = data?.current_total?.credit_total
            //   ? data?.current_total?.credit_total
            //   : "";
            // console.log("total data:" + data.count);
            // pagination
            // paginationData.value["total"] = data.count;
            // var pg =
            //   paginationData.value["activePage"] *
            //   paginationData.value["perPage"];
            // paginationData.value["start"] =
            //   pg - paginationData.value["perPage"];
            // paginationData.value["end"] =
            //   paginationData.value["start"] +
            //   paginationData.value["perPage"] * 1;

            // paginationData.value["totPage"] = Math.ceil(
            //   data.count / paginationData.value["perPage"]
            // );

            // paginationData.value["mmaxPageg"] =
            //   paginationData.value["totPage"] <
            //   paginationData.value["mmaxPageg"]
            //     ? paginationData.value["totPage"]
            //     : paginationData.value["mmaxPageg"];

            var resultsM = ref<Array<WICompanies>>([]);
            var resultsN = ref<Array<WICompanies>>([]);
            var status_label = "";
            var status_color = "";
            var active_label = "";
            var active_color = "";
            incomeTotal.value = 0;
            for (let j = 0; j < data.assets_data.length; j++) {
              if (data.assets_data[j]["active"]) {
                active_label = "Yes";
                active_color = "success";
              } else {
                active_label = "No";
                active_color = "danger";
              }

              resultsM.value = Array({
                account_name: data.assets_data[j]["account_name"],
                amount: data.assets_data[j]["Amount"],
              });
              incomeTotal.value += data.assets_data[j]["Amount"];
              tableData.value.splice(
                j,
                resultsM.value.length,
                ...resultsM.value
              );
            }
            expenditureTotal.value = 0;
            loadingData.value = false;
            for (let j = 0; j < data.liabilities_data.length; j++) {
              if (data.liabilities_data[j]["active"]) {
                active_label = "Yes";
                active_color = "success";
              } else {
                active_label = "No";
                active_color = "danger";
              }

              resultsN.value = Array({
                account_name: data.liabilities_data[j]["account_name"],
                amount: data.liabilities_data[j]["Amount"],
              });
              expenditureTotal.value += data.liabilities_data[j]["Amount"];
              tableDataExpendeture.value.splice(
                j,
                resultsN.value.length,
                ...resultsN.value
              );
            }
            loadingData.value = false;
          })
          .catch(({ response }) => {
            console.log(response);
          });
      } catch (e) {
        console.log(e);
      }
    };

    // function fnAPI(){

    //   companies_data = [
    //     {
    //       id: Math.floor(Math.random() * 99999) + 1,
    //       name: " (India) llp",
    //       email: "admin@metalxperts.com",
    //       company: "-",
    //       payment: {
    //         icon: "/media/svg/card-logos/visa.svg",
    //         ccnumber: "**** " + Math.floor(Math.random() * (9999 - 1000 + 1) + 1000),
    //         label: "visa",
    //       },
    //       date: "01 Dec 2020, 10:12 am",
    //     }
    //   ];

    //   console.log(companies_data);
    //   tableData = ref<Array<ICompanies>>(companies_data);
    //   initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    //   console.log(tableData);

    // }

    onUpdated(() => {
      // console.log("aaap");
      // console.log(list_data);
    });

    // var tableData = ref<Array<WICompanies>>([]);
    const initCustomers = ref<Array<WICompanies>>([]);

    onMounted(async () => {
      // await getReports();
      setCurrentPageBreadcrumbs("Assets Libility Report", []);
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
      console.log(tableData.value.length);
    });

    const deleteFewCustomers = () => {
      checkedCompany.value.forEach((item) => {
        deleteCustomer(item);
      });
      checkedCompany.value.length = 0;
    };

    const deleteCustomer = (id) => {
      // for (let i = 0; i < tableData.value.length; i++) {
      //   if (tableData.value[i].account_id === id) {
      //     tableData.value.splice(i, 1);
      //   }
      // }
    };

    const search = ref<string>("");
    const fromDate = ref<string>("");
    const toDate = ref<string>("");
    const creditTotal = ref<string>("");
    const debitTotal = ref<string>("");
    const incomeTotal = ref<number>(0);
    const expenditureTotal = ref<number>(0);
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<WICompanies> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      tableData,
      tableDataExpendeture,
      deleteCustomer,
      search,
      searchItems,
      checkedCompany,
      deleteFewCustomers,
      count,
      getReports,
      paginationData,
      changePageChange,
      refreshData,
      loadingData,
      fromDate,
      toDate,
      chkData,
      creditTotal,
      debitTotal,
      incomeTotal,
      expenditureTotal,
    };
  },
});
